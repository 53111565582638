import i18n from '@/locale'
import { getPrintTemplateList, handlePrintData, handleUpdataPrintData } from '@/api/set/printTemplateApi'
import { GlobalEventType } from '@/constants'
import modules from '@/views/set/printTemplate/config'
import { mapGetters } from 'vuex'
/**
 * 关闭页面返回刷新列表
 */
export const CloseToRefreshList = {
  /**
   * 路由离开时，设置目标页是否需要刷新列表
   * @param {*} to
   * @param {*} from
   * @param {*} next
   */
  beforeRouteLeave(to, from, next) {
    const fromNeedRefreshList = from.meta?.needRefreshList
    // needRefreshList 没有定义在data中，是因为它只作为一个普通变量，无需响应式
    if (!this?.needRefreshList && !fromNeedRefreshList) return next()
    to.meta.needRefreshList = this?.needRefreshList || fromNeedRefreshList
    from.meta.needRefreshList = false
    next()
  },
  methods: {
    /**
     * 关闭当前页（跳转到指定页面）
     * @param {string | Location} to 可选参数,与路由对象的`push`方法入参一致，如果未指定 `to`,则默认打开前一个标签
     * @example
     * this.closeSelf('/xxx')
     * this.closeSelf(this.$route)
     * @returns
     */
    async closeSelf(to) {
      await this.$mgCloseEditPageConfirm(this.$route.name)
      const visiteRoutes = this.$store.getters['tabsBar/visiteRoutes']
      if (to && !(to instanceof Event)) {
        let location = this.$router.match(to)
        if (location.path !== this.$route.path) {
          const targetRoute = visiteRoutes.find((item) => item.path === location.path)
          if (targetRoute) location = targetRoute
          this.$store.dispatch('tabsBar/removeTab', this.$route)
          this.$router.push(location)
          return
        }
      }
      // 未指定目标路由走默认逻辑
      const index = visiteRoutes.findIndex((item) => item.path === this.$route.path)
      const toRoute = visiteRoutes[index - 1] || visiteRoutes[index + 1]
      if (toRoute) this.$router.push(toRoute)
      this.$store.dispatch('tabsBar/removeTab', visiteRoutes[index])
    },
  },
}
/**
 * 初始化打印模板
 */
export const printMixin = {
  data() {
    return {
      printParamsObj: {
        printOptions: [],
        printPreviewOptions: [],
        printAssignOptions: [],
        printPreviewAssignOptions: [],
        aggregationParams: null,
      },
    }
  },
  methods: {
    /**
     * 获取模板数据
     *
     * @param { String } billType 单据类型
     * @param { String } billIdField 单据类型id表示的字段
     * @param { Array } codeJointNames 需要code-name拼接处理的字段集合 比如：[{ name: '***name', code: '***Code' }]
     * @param { String } barcodeField 单据需要条形码的单据字段名
     * @param { Array } formatDateFields 需要格式化日期的字段名
     * @returns { printOptions,  printPreviewOptions } 返回直接和预览打印各自数据集
     * @param { Array } usePrintTemplates 多模板是指定使用某个模板
     * handleCommandDetail 模板监听事件
     */
    handlePrintTemplateData({
      billType = '',
      billIdField = '',
      codeJointNames = [],
      formatDateFields = [],
      barcodeField = '',
      boxNoField = '',
      billNoField = '',
      usePrintTemplates = [],
      sortBy = '',
      isTasksPrint = false,
      ids = [],
    } = {}) {
      const printParamsObj = this.printParamsObj
      if (!billType || !billIdField) return
      return new Promise((resolve, reject) => {
        const paramsObj = {
          page: 1,
          size: 10000,
          billTypes: [billType],
          statuses: ['ENABLE'],
        }
        getPrintTemplateList(paramsObj)
          .then((res) => {
            const { data } = res

            // 直接
            printParamsObj.printAssignOptions =
              (data?.items &&
                data?.items?.map((item) => {
                  return {
                    ...item,
                    label: item.templateName,
                    value: item.templateContent,
                    printWay: 'direct',
                    event: (row, command) =>
                      this.handleCommandDetail({
                        ids: ids?.length > 0 ? ids : [row[billIdField]],
                        row,
                        command,
                        type: 'direct',
                        billType,
                        billIdField,
                        codeJointNames,
                        formatDateFields,
                        barcodeField,
                        billNoField,
                        boxNoField,
                        sortBy,
                        isTasksPrint,
                      }),
                  }
                })) ||
              []
            printParamsObj.printOptions =
              usePrintTemplates.length > 0
                ? printParamsObj.printAssignOptions.filter((item) => usePrintTemplates.includes(item.type))
                : printParamsObj.printAssignOptions

            // 预览
            printParamsObj.printPreviewAssignOptions =
              (data?.items &&
                data?.items?.map((item) => {
                  return {
                    ...item,
                    label: item.templateName,
                    value: item.templateContent,
                    printWay: 'preview',
                    event: (row, command) =>
                      this.handleCommandDetail({
                        ids: ids?.length > 0 ? ids : [row[billIdField]],
                        row,
                        command,
                        type: 'preview',
                        billType,
                        billIdField,
                        codeJointNames,
                        formatDateFields,
                        barcodeField,
                        billNoField,
                        boxNoField,
                        sortBy,
                        isTasksPrint,
                      }),
                  }
                })) ||
              []
            printParamsObj.printPreviewOptions =
              usePrintTemplates.length > 0
                ? printParamsObj.printPreviewAssignOptions.filter((item) => usePrintTemplates.includes(item.type))
                : printParamsObj.printPreviewAssignOptions
            resolve({
              printOptions: printParamsObj.printOptions,
              printPreviewOptions: printParamsObj.printPreviewOptions,
            })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 触发打印
     * @param { Array }  batchPrintBillType 批量打印 需要入参ids的单据类型集合
     * @param { Array }  ids 批量打印id集合
     * @param { Object } row 当前行数据
     * @param { Object } command 当前触发的打印模板
     * @param { String } type 打印类型 可选值: { 直接打印: direct; 预览打印: preview }
     * @param { String } billType 单据类型
     * @param { String } billIdField 单据类型id字段名
     * @param { Array } codeJointNames 需要code-name拼接处理的字段名
     * @param { Array } formatDateFields 需要格式化日期的字段名
     * @param { String } barcodeField 单据需要条形码的单据字段名
     * @param { String } boxNoField 复核单箱号字段名
     * @param { String } billNoField 单号字段名
     * @param { Boolean } isRedrawSetDesign 是否重新绘制模板
     * @param { Boolean } isTasksPrint 是否是队列打印
     * @param { String } sortBy 明细排序字段
     * @param { String } mainSortBy 主表排序字段
     */
    handleCommandDetail({
      batchPrintBillType = ['SALE_ORDER'],
      ids = [],
      row = null,
      command = null,
      type = '',
      billType = '',
      billIdField = '',
      codeJointNames = [],
      formatDateFields = [],
      barcodeField = '',
      boxNoField = '',
      billNoField = '',
      isRefresh = true,
      sortBy = '',
      isRedrawSetDesign = false,
      isTasksPrint = false,
      mainSortBy = '',
    } = {}) {
      const __that = this
      const dayJs = this.dayjs
      if (!row || !command || !type || !billType || !billIdField)
        return this.$mgMessage(i18n.t('打印所需参数异常，请联系管理员核对后重试'), 'error')
      const printPanels = (command?.templateContent && JSON.parse(command?.templateContent)) || null
      if (!printPanels) return this.$mgMessage(i18n.t('该模板异常，请核对后重试'), 'error')
      return new Promise((resolve, reject) => {
        const printParamsObj = this.printParamsObj

        // 单次打印
        const singlePrintParams = {
          billTypeEnum: billType,
          boxNo: row[boxNoField],
          // 兼容没有批量打印的单据，故2个都传，后端根据需要取值
          ids: [row[billIdField]],
          id: row[billIdField],
          saleOrderNo: row[billNoField],
          sortBy,
        }
        // 批量打印
        const batchPrintParams = {
          billTypeEnum: billType,
          ids,
          sortBy,
          mainSortBy,
        }
        const aggregationParams =
          ids?.length > 0 && batchPrintBillType.includes(billType) ? batchPrintParams : singlePrintParams
        printParamsObj.aggregationParams = aggregationParams
        handlePrintData(aggregationParams)
          .then((printDataRes) => {
            /**
             * 单次打印数据处理 -- 开始
             */
            const data =
              Object.prototype.toString.call(printDataRes?.data) === '[object Array]'
                ? printDataRes?.data?.find((item) => item[billIdField] === row[billIdField])
                : printDataRes?.data
            let printData = {},
              printDetailData = []
            if (data) {
              const printLists =
                data?.items?.map((item, i) => {
                  return {
                    ...item,
                    index: i + 1,
                  }
                }) || []
              // 需要拼接的字段
              const codeJointedNames = {}
              codeJointNames.map((item) => {
                const newItems = {
                  [item.name]:
                    data[item.code] && data[item.name]
                      ? `${data[item.code]}-${data[item.name]}`
                      : data[item.name] || '',
                }
                Object.assign(codeJointedNames, newItems)
              })
              // 日期
              const formatDates = {}
              formatDateFields.map((item) => {
                const newItems = {
                  [item]: data[item] && dayJs(data[item]).format('YYYY-MM-DD HH:mm:ss'),
                }
                Object.assign(formatDates, newItems)
              })
              // 条形码
              const barcode = {}
              if (barcodeField) Object.assign(barcode, { barcode: data[barcodeField] })

              // 主表 + 明细的数据处理
              printData = {
                ...data,
                ...codeJointedNames,
                ...formatDates,
                ...barcode,
                printTime: dayJs().format('YYYY-MM-DD HH:mm:ss'),
                printLists,
              }

              // 纯明细数据处理
              printDetailData = printLists
            }

            /**
             * 单次打印数据处理 -- 结束
             */

            // 预览打印
            this.handlePrintInit(printPanels).then((res) => {
              if (res) {
                if (type === 'direct' && !window?.hiwebSocket.opened)
                  return this.$mgMessage(i18n.t('客户端未连接,无法直接打印'), 'error')
                // 直接打印
                if (type === 'direct' && window?.hiwebSocket.opened) {
                  if (printPanels?.panels) {
                    const { width, height } = printPanels.panels[0]
                    const printPageSize = {
                      width: BN.times(width, 1000).toNumber(),
                      height: BN.times(BN.minus(height, 1), 1000).toNumber(),
                    }
                    const printerList = res.getPrinterList()
                    // 当前单据类型
                    const currentBillTypeItem = modules.common.templateJsonOptions.find(
                      (item) => item.value === command.billType
                    )
                    const printTaskTitle = i18n.t('晨光-{slot1}-{slot2}', {
                      slot1: currentBillTypeItem.label,
                      slot2: command.templateName,
                    })

                    // 直接打印所需额外参数
                    const print2Params = {
                      printer: command?.defaultPrinterName || printerList,
                      title: printTaskTitle,
                      pageSize: printPageSize,
                      styleHandler: () => {
                        const css =
                          '<link href="../../public/static/css/print-lock.css" media="print" rel="stylesheet">'
                        return css
                      },
                    }

                    /**
                     * printer: 打印机列表
                     * title:   打印任务标题
                     * pageSize: 因为这里的pageSize是对electronj开发的客户端设置的打印纸张规格,
                     * 其所用的单位是μm, 而插件hiPrint打印的纸张规格用的是mm
                     * 所以需要用mm去换算μm(1mm === 1000μm)
                     */
                    if (isTasksPrint && !printDataRes.data?.length)
                      return this.$mgMessage(i18n.t('打印数据为空，请核对后重试'), 'warning')

                    // 是否走队列打印
                    if (isTasksPrint && printDataRes.data?.length > 0) {
                      /**
                       * 批量打印数据处理 -- 开始
                       */
                      const batchPrintData = printDataRes?.data.map((printDataResItem) => {
                        // 需要拼接的字段
                        const codeJointedNames = {}
                        codeJointNames.map((item) => {
                          const newItems = {
                            [item.name]:
                              printDataResItem[item.code] && printDataResItem[item.name]
                                ? `${printDataResItem[item.code]}-${printDataResItem[item.name]}`
                                : printDataResItem[item.name] || '',
                          }
                          Object.assign(codeJointedNames, newItems)
                        })
                        // 日期
                        const formatDates = {}
                        formatDateFields.map((item) => {
                          const newItems = {
                            [item]:
                              printDataResItem[item] && dayJs(printDataResItem[item]).format('YYYY-MM-DD HH:mm:ss'),
                          }
                          Object.assign(formatDates, newItems)
                        })
                        // 条形码
                        const barcode = {}
                        if (barcodeField) Object.assign(barcode, { barcode: printDataResItem[barcodeField] })

                        return {
                          ...printDataResItem,
                          ...codeJointedNames,
                          ...formatDates,
                          ...barcode,
                          printTime: dayJs().format('YYYY-MM-DD HH:mm:ss'),
                          printLists:
                            printDataResItem?.items?.map((item, i) => {
                              return {
                                ...item,
                                index: i + 1,
                              }
                            }) || [],
                        }
                      })
                      /**
                       * 批量打印数据处理 -- 结束
                       */
                      __that
                        .handleTasksPrint({
                          printLists: command?.templateType === 'BILL' ? batchPrintData : printDetailData,
                          print2Params,
                        })
                        .then((taskPrintRes) => {
                          if (aggregationParams.billTypeEnum === 'SALE_ORDER') {
                            resolve(taskPrintRes)
                          } else if (taskPrintRes.tasks.length === 0) {
                            resolve(taskPrintRes)
                          }
                        })
                        .catch((err) => {
                          reject(err)
                        })
                        .finally(() => {
                          __that.handlePrintSuccess({ data: aggregationParams, isRefresh })
                        })
                    } else {
                      res
                        .print2(command?.templateType === 'BILL' ? printData : printDetailData, print2Params)
                        .then(() => {
                          this.$mgMessage(i18n.t('已连接打印机, 请勿频繁操作'), 'warning')
                        })
                        .finally(() => {
                          __that.handlePrintSuccess({ data: aggregationParams, isRefresh })
                        })
                      res.on('printSuccess', function () {
                        __that.$mgMessage(i18n.t('正在打印中, 请稍候'), 'success')
                      })
                    }
                  }
                }

                // 预览打印
                if (type === 'preview') {
                  this.handlePrintPreView(
                    command?.templateType === 'BILL' ? printData : printDetailData,
                    'printPreviewRef'
                  )
                  // 是否重绘
                  if (isRedrawSetDesign) this?.$refs['mgPrintParentConRef']?.handleSetDesign()
                }
              }
            })
          })
          .catch((error) => {
            console.log(error, '获取打印数据接口有误！！！')
          })
      })
    },
    /**
     * 更新打印次数
     * 为了避免有的单据定义的表格ref属性不同，所以加一变量来兼容
     * @param { String } tableRef 表格的ref 默认值为：mgTable
     */
    async handlePrintSuccess({ tableRef = 'mgTable', data = null, isRefresh = true } = {}) {
      const printParamsObj = this.printParamsObj
      if (!data && !printParamsObj.aggregationParams)
        return this.$mgMessage(i18n.t('更新打印次数所需参数有误，请核对后重试'), 'error')
      try {
        const aggregationParams = data || printParamsObj.aggregationParams
        await handleUpdataPrintData(aggregationParams)
        if (isRefresh) {
          this.$nextTick(() => {
            this.$refs[tableRef]?.loadData()
          })
        }
      } catch (error) {
        throw new Error(error)
      }
    },
  },
}

/**
 * 全局快捷键操作-操作页
 */
export const GlobalKeyboardActionMixinForOperate = {
  activated() {
    this.$GlobalEventBus.on(GlobalEventType.CANCEL, this.keyboardCancelAction)
    this.$GlobalEventBus.on(GlobalEventType.SAVE, this.keyboardSaveAction)
    this.$GlobalEventBus.on(GlobalEventType.AUDIT, this.keyboardAuditAction)
    this.$GlobalEventBus.on(GlobalEventType.RECORDING, this.keyboardRecordingAction)
    this.$GlobalEventBus.on(GlobalEventType.FAST_ADD, this.keyboardFastAddAction)
    this.$GlobalEventBus.on(GlobalEventType.CLEAR_ITEMS, this.keyboardClearItemsAction)
    this.$GlobalEventBus.on(GlobalEventType.PRINT, this.keyboardPrintAction)
    this.$GlobalEventBus.on(GlobalEventType.CUSTOMER_CHANGE, this.keyboardCustomerChange)
    this.$once('hook:deactivated', () => {
      this.$GlobalEventBus.off(GlobalEventType.CANCEL, this.keyboardCancelAction)
      this.$GlobalEventBus.off(GlobalEventType.SAVE, this.keyboardSaveAction)
      this.$GlobalEventBus.off(GlobalEventType.AUDIT, this.keyboardAuditAction)
      this.$GlobalEventBus.off(GlobalEventType.RECORDING, this.keyboardRecordingAction)
      this.$GlobalEventBus.off(GlobalEventType.FAST_ADD, this.keyboardFastAddAction)
      this.$GlobalEventBus.off(GlobalEventType.CLEAR_ITEMS, this.keyboardClearItemsAction)
      this.$GlobalEventBus.off(GlobalEventType.PRINT, this.keyboardPrintAction)
      this.$GlobalEventBus.off(GlobalEventType.CUSTOMER_CHANGE, this.keyboardCustomerChange)
    })
  },
  methods: {
    // 返回列表/取消
    keyboardCancelAction() {
      if (!this.$refs.cancel?.disabled) {
        this.$refs.cancel?.$el.dispatchEvent(new Event('click'))
      }
    },
    // 保存
    keyboardSaveAction() {
      if (!this.$refs.save?.disabled) {
        this.$refs.save?.$el.dispatchEvent(new Event('click'))
      }
    },
    // 审核
    keyboardAuditAction() {
      if (!this.$refs.audit?.disabled) {
        this.$refs.audit?.$el.dispatchEvent(new Event('click'))
      }
    },
    // 入账
    keyboardRecordingAction() {
      if (!this.$refs.recording?.disabled) {
        this.$refs.recording?.$el.dispatchEvent(new Event('click'))
      }
    },
    // 快速添加
    keyboardFastAddAction() {
      if (!this.$refs.fastAddBtn?.disabled) {
        this.$refs.fastAddBtn?.$el.dispatchEvent(new Event('click'))
      }
    },
    // 清空明细
    keyboardClearItemsAction() {
      if (!this.$refs.clearItems?.disabled) {
        this.$refs.clearItems?.$el.dispatchEvent(new Event('click'))
      }
    },
    // 打印
    keyboardPrintAction() {
      if (!this.$refs.print?.disabled) {
        this.$refs.print?.$el.querySelector('button').dispatchEvent(new Event('click'))
      }
    },
    // 更换客户
    keyboardCustomerChange() {
      if (!this.$refs.customerChange?.disabled) {
        this.$refs.customerChange?.$el.dispatchEvent(new Event('click'))
      }
    },
  },
}
/**
 * 全局快捷键操作-列表页
 */
export const GlobalKeyboardActionMixinForList = {
  activated() {
    this.$GlobalEventBus.on(GlobalEventType.ADD, this.keyboardAddAction)
    this.$once('hook:deactivated', () => {
      this.$GlobalEventBus.off(GlobalEventType.ADD, this.keyboardAddAction)
    })
  },
  methods: {
    // 新增
    keyboardAddAction() {
      if (!this.$refs.add?.disabled) {
        this.$refs.add?.$el.dispatchEvent(new Event('click'))
      }
    },
  },
}

/**
 * 组装 cacheKey
 */

export const CacheKey = {
  computed: {
    ...mapGetters({
      belongCode: 'user/belongCode',
      employeeNo: 'user/employeeNo',
    }),

    cacheKey() {
      return (billType, eventType = '') => {
        return [this.belongCode, billType, this.employeeNo, eventType].join('_')
      }
    },
  },
}
/**
 * 全局权限
 */
export const GlobalPermissionMixin = {
  computed: {
    // 是否禁用展示成本价
    isDisabledGlobalPermissionCostPrice() {
      const route = this.$router.match('/globalFiledPermission')
      if (route.name === '404') return true
      return !route.meta?.buttons?.some((n) => n.path === 'costPrice')
    },
  },
}
